<template>
    <div class="battle-page">
        <div class="container">
            <div class="hero">
                <div class="big-title">请选择出战角色</div>
                <img src="../assets/image/User_Character_01.png" alt="" class="avatar">
                <div class="selector">
                    <div class="prew"></div>
                    <div class="info-block">
                        <div class="name">
                            Magician
                        </div>
                        <div class="level">
                            Level 8
                        </div>
                    </div>
                    <div class="next"></div>
                </div>
                <div class="team-info">
                    <div class="line available">
                        <div class="title">
                            可出战角色
                        </div>
                        <div class="content">
                            5
                        </div>
                    </div>
                    <div class="line resting">
                        <div class="title">
                            休息中的角色
                        </div>
                        <div class="content">
                            2
                        </div>
                    </div>
                </div>

            </div>
            <div class="monster">
                <div class="big-title">请选择战斗目标</div>
                <div class="monster-gallery">
                    <div class="monster-card">
                        <div class="img">
                            <img src="../assets/image/Demon_01.gif" alt="">
                            <div class="monster-intro">
                                <div class="name">Demon</div>
                                <div class="line-title">Level</div>
                                <div class="line-content">1</div>
                                <div class="line-title">Win Rate</div>
                                <div class="line-content">~80%</div>
                                <div class="line-title">Reward</div>
                                <div class="line-content">100 {{game.token}}</div>
                                <div class="line-title">Exp</div>
                                <div class="line-content">10</div>
                            </div>
                        </div>
                        <div class="btn battle">Battle</div>


                    </div>
                    <div class="monster-card">
                        <div class="img">
                            <img src="../assets/image/Demon_02_update.png" alt="">
                            <div class="monster-intro">
                                <div class="name">Iron Demon</div>
                                <div class="line-title">Level</div>
                                <div class="line-content">1</div>
                                <div class="line-title">Win Rate</div>
                                <div class="line-content">~80%</div>
                                <div class="line-title">Reward</div>
                                <div class="line-content">100 {{game.token}}</div>
                                <div class="line-title">Exp</div>
                                <div class="line-content">10</div>
                            </div>
                        </div>
                        <div class="btn battle">Battle</div>


                    </div>
                    <div class="monster-card">
                        <div class="img">
                            <img src="../assets/image/Demon_03_update.png" alt="">
                            <div class="monster-intro">
                                <div class="name">Blood Demon</div>
                                <div class="line-title">Level</div>
                                <div class="line-content">1</div>
                                <div class="line-title">Win Rate</div>
                                <div class="line-content">~80%</div>
                                <div class="line-title">Reward</div>
                                <div class="line-content">100 {{game.token}}</div>
                                <div class="line-title">Exp</div>
                                <div class="line-content">10</div>
                            </div>
                        </div>
                        <div class="btn battle">Battle</div>


                    </div>
                    <div class="monster-card">
                        <div class="img">
                            <img src="../assets/image/Demon_04.png" alt="">
                            <div class="monster-intro">
                                <div class="name">Psychic Demon</div>
                                <div class="line-title">Level</div>
                                <div class="line-content">1</div>
                                <div class="line-title">Win Rate</div>
                                <div class="line-content">~80%</div>
                                <div class="line-title">Reward</div>
                                <div class="line-content">100 {{game.token}}</div>
                                <div class="line-title">Exp</div>
                                <div class="line-content">10</div>
                            </div>
                        </div>
                        <div class="btn battle">Battle</div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>
<script>
    export default {

    }

</script>
<style lang='scss'>
    .battle-page {
        display: flex;
        color: white;
        font-family: bigfont;
        background-image: url('../assets/image/Battle_bg.jpg');
        background-size: cover;
        background-position: center;

        .container {
            display: flex;
            align-items: top;
            margin-top: 50px;
            justify-content: space-between;
        }

        .monster {
            margin-left: 100px;

            .big-title {
                color: #FFA900;
                font-size: 22px;
                margin-bottom: 10px;
                text-align: center;
            }

            .monster-gallery {
                display: flex;
                flex-wrap: wrap;
                width: 640px;

                .monster-card {
                    width: 300px;
                    margin-bottom: 40px;
                    .btn{
                        background-color: #FFA900;
                        width: 100%;
                        height: 33px;
                        line-height: 33px;
                        text-align: center;
                        font-size: 14px;
                        border-radius: 5px;
                    }
                    .img {
                        height: 280px;
                        position: relative;
                        img {
                            width: 300px;
                        }
                        .monster-intro {
                            position: absolute;
                            right: 0px;
                            top: 0px;
                            .name{
                                font-size: 22px;
                                width: 85px;
                                line-height: 30px;
                                
                            }
                            .line-title{
                                font-size: 12px;
                                margin-bottom: 5px;
                            }
                            .line-content{
                                font-size: 22px;
                                margin-bottom: 5px;
                            }
                        }
                    }

                    &:nth-child(2n) {
                        margin-left: 40px;
                    }
                    

                }
            }

        }

        .hero {
            width: 250px;
            text-align: center;

            .big-title {
                color: #FFA900;
                font-size: 22px;
                margin-bottom: 10px;
                margin-bottom: 40px;
            }

            .avatar {
                width: 300px;
            }

            .selector {
                display: flex;
                color: #FFA900;
                justify-content: space-between;
                margin-bottom: 40px;

                .info-block {
                    .name {
                        font-size: 18px;

                    }

                    .level {
                        font-size: 12px;
                    }
                }
            }


            .team-info {
                display: flex;
                justify-content: space-between;

                .line {
                    &.available {
                        margin-right: 10px;
                    }

                    .title {
                        font-size: 15px;
                        margin-bottom: 10px;
                    }

                    .content {
                        font-size: 25px;
                    }
                }

            }
        }
    }

</style>
